import {CATEGORIES, USER_INCLUDES} from 'app/constants';

export const statuses = [CATEGORIES.ACTIVE, CATEGORIES.FORMER];

export const clientQueryParams = {
  include: [
    'total_count',
    'skill_tags',
    ...USER_INCLUDES.supplierIncludeDetails,
  ],
  expand: ['supplier', 'resource', 'requisition', 'applicant', 'integration'],
};

export const supplierQueryParams = {
  include: ['total_count', 'skill_tags'],
  expand: ['resource', 'requisition', 'applicant'],
};

export const tabsData = [
  {key: 'all', title: 'All', route: '/candidates/all'},
  {key: 'active', title: 'Active', route: '/candidates/active'},
  {key: 'former', title: 'Former', route: '/candidates/former'},
];

export const getPageQueries = (isSupplier = false, isView = false) => {
  const queryParams = isSupplier ? supplierQueryParams : clientQueryParams;
  if (isView) {
    return queryParams;
  }
  return {
    all: {
      queryParams,
      title: 'All Candidates',
    },
    active: {
      queryParams: {
        ...queryParams,
        status: 'active',
      },
      title: 'Active Candidates',
      label: 'Active',
      rowTitle: 'Active Candidates',
      empty: 'No Active Candidates',
    },
    former: {
      queryParams: {
        ...queryParams,
        status: 'former',
      },
      title: 'Former Candidates',
      label: 'Former',
      rowTitle: 'Former Candidates',
      empty: 'No Former Candidates',
    },
  };
};
