import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import InformationForm from 'app/shared/requisitions/RequisitionSteps/SkillsResponsibilities/InformationForm';
import InformationView from 'app/shared/requisitions/RequisitionSteps/SkillsResponsibilities/InformationView';
import PropTypes from 'prop-types';
import React from 'react';

const SkillsResponsibilities = ({requisitionModal}) => {
  const {currentStep, nextStep, setStepData, stepData} = useStep();
  return (
    <React.Fragment>
      <Typography.Paragraph>
        Specify responsibilities, skills and experience
      </Typography.Paragraph>
      {currentStep > 2 && stepData ? (
        <InformationView requisitionModal={requisitionModal} />
      ) : (
        currentStep === 2 && (
          <InformationForm
            onSave={setStepData}
            onContinue={nextStep}
            initialValues={{
              job_overview: stepData?.job_overview,
              job_role_description: stepData?.job_role_description,
              job_responsibilities: stepData?.job_responsibilities,
              job_qualifications: stepData?.job_qualifications,
              skill_tags: stepData?.skill_tags
                ? stepData?.skill_tags.map((tag) => tag.id)
                : [],
              skillTags: stepData?.skill_tags ?? [],
            }}
          />
        )
      )}
    </React.Fragment>
  );
};

SkillsResponsibilities.propTypes = {
  requisitionModal: PropTypes.object,
};

export default SkillsResponsibilities;
