import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {Typography} from 'antd';

export const useGetServiceColumns = () => {
  return [
    {
      title: 'Month End Date',
      key: 'end_date',
      dataIndex: 'end_date',
      width: 200,
      align: 'left',
      render: (date) => date,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      render: (val) => <Typography.Text>{val}</Typography.Text>,
    },
    {
      title: 'QTY',
      dataIndex: 'qty',
      key: 'qty',
      render: () => <Typography.Text>1</Typography.Text>,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      width: 140,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Tax',
      dataIndex: 'total_tax',
      key: 'total_tax',
      width: 120,
      render: (amount) => <AppAmountView amount={amount} />,
    },
    {
      title: 'Final Billable Amount',
      dataIndex: 'final',
      key: 'finalAmount',
      align: 'right',
      width: 200,
      render: (amount) => <AppAmountView amount={amount} />,
    },
  ];
};
