import AppAmountView from '@wieldy/components/helperComponents/AppAmountView';
import {APP_CURRENCY} from '@wieldy/constants/AppDataConsts';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {omit} from 'lodash';

import {TAB_STATUSES} from '../constants';
import {formatRelativeTime} from '../utils/helpers/AppHelper';
import {
  formatBillingRateRage,
  formatDate,
  getAddress,
  getDuration,
  getFullName,
  getPhoneNumber,
} from '../utils/helpers/DataFormatHelper';

class BaseModal {
  constructor(props) {
    this._orignalProps = props ?? {};
  }

  getLocation(data) {
    const theCountry = data?.job_country || data?.country;
    const state = data?.us_state || data?.united_states;
    if (Array.isArray(theCountry)) {
      return getAddress({
        country: theCountry[0],
        city: data?.city,
        us_state: state,
      });
    }
    if (theCountry) {
      return getAddress({
        country: theCountry,
        city: data?.city,
        us_state: state,
      });
    }
    if (data?.city) {
      return data.city;
    }
    return 'Any Country';
  }

  formatDate(timestamp, format) {
    return formatDate(timestamp, format);
  }
  getDuration(value, key = 'days') {
    return getDuration(value, key);
  }
  formatRelativeTime(timestamp) {
    return formatRelativeTime(timestamp);
  }
  formatBillingRateRage(...args) {
    return formatBillingRateRage(...args);
  }

  formatAmountView(amount) {
    return <AppAmountView amount={amount} operator={APP_CURRENCY} />;
  }
  getFullName(object) {
    return getFullName(object);
  }
  getYesNo(bool) {
    return bool ? 'Yes' : 'No';
  }
  getStatus(key) {
    if (!key) {
      return '';
    }
    return TAB_STATUSES[key] || capitalizedAllWords(key.replace('_', ' '));
  }
  getUser(user, withLastName = true) {
    // Return user id if user is not object
    if (!user) {
      return '';
    }
    if (typeof user !== 'object') {
      return user;
    }
    return {
      id: user?.id ?? null,
      name: user?.name
        ? user.name
        : getFullName(
            withLastName ? user : omit(user, 'last_name', 'lastName'),
          ),
      email: user?.email,
      phoneNumber: this.getPhoneNumber(user?.phone_number),
    };
  }
  buildExpandSupplier(object, isIncluded = false) {
    if (!object) {
      return null;
    }
    if (isIncluded && typeof object.supplier === 'object') {
      return {
        ...object.supplier,
        active_clients: object.supplier_active_clients,
        completed_engagements: object.supplier_completed_engagements,
        available_resources: object.supplier_available_resources,
        inquiries: object.supplier_inquiries,
        submissions: object.supplier_submissions,
        engagements: object.supplier_engagements,
        relation_status:
          object.relation_status || object.supplier_relationship_status,
      };
    }
    return object.supplier;
  }
  buildExpandClient(object, isIncluded) {
    if (!object) {
      return null;
    }
    if (isIncluded && typeof object.client === 'object') {
      return {
        ...object.client,
        active_suppliers: object.client_active_suppliers,
        open_requisitions: object.client_open_requisitions,
        engaged_resources: object.client_engaged_resources,
        inquiries: object.client_inquiries || object.common_inquiries,
        submissions: object.client_submissions || object.common_submissions,
        engagements: object.client_engagements || object.common_engagements,
        relation_status:
          object.relation_status || object.client_relationship_status,
      };
    }
  }
  getPhoneNumber(phoneNumber) {
    return getPhoneNumber(phoneNumber);
  }
  getInitials(fullName) {
    fullName = fullName.trim();
    if (fullName === '') {
      return '';
    }
    // Split the string into parts based on one or more spaces
    const nameParts = fullName.split(/\s+/);
    // Handle cases with no valid parts
    if (nameParts.length === 0) {
      return '';
    }
    // Extract the first letter of each non-empty part and capitalize it
    const initials = nameParts
      .filter((part) => part.length > 0) // Ensure part is not empty
      .map((part) => part.charAt(0).toUpperCase())
      .slice(0, 2) // Take at most 2 letters
      .join('');

    return initials;
  }
}

export default BaseModal;
