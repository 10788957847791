import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Form, Input, Modal, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {RiCloseLine} from 'react-icons/ri';

import {
  CANDIDATE_REJECT_REASON_OPTIONS,
  CANDIDATE_STATUS_OPTIONS,
} from '../../../../../../constants/candidate-statuses';
import {useStatus} from '../../../../../../hooks/useStatus';

const ChangeCandidateModal = ({
  isOpenModal,
  handleClose,
  handleSuccess,
  selectedCandidate,
  options,
  rejectOptions,
  isIntegrated,
}) => {
  const [form] = Form.useForm();
  const status = Form.useWatch('status', form);
  const isDisableStage = status !== 'active';
  const {isRejected} = useStatus(status);

  useEffect(() => {
    if (selectedCandidate && isOpenModal) {
      const {
        status_key,
        reject_reason,
        integration_reject_reason,
        reject_reason_notes,
        job_interview_stage,
      } = selectedCandidate;
      form.setFieldValue('status', status_key);
      form.setFieldValue('reject_reason_notes', reject_reason_notes);
      form.setFieldValue('job_interview_stage', job_interview_stage);
      if (isIntegrated && integration_reject_reason) {
        form.setFieldValue('reject_reason', integration_reject_reason.id);
      } else if (!isIntegrated) {
        form.setFieldValue('reject_reason', reject_reason);
      }
    }
  }, [form, selectedCandidate, isOpenModal, isIntegrated]);

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
    }
  }, [form, isOpenModal]);

  return (
    <Modal
      className='submission-reject-modal'
      title='Change Candidate Status / Stage'
      open={isOpenModal}
      cancelButtonProps={{type: 'primary', htmlType: 'button', ghost: true}}
      closeIcon={<RiCloseLine fontSize={24} />}
      okText='Change'
      width={844}
      onOk={form.submit}
      onCancel={handleClose}
      destroyOnClose={true}>
      <Typography.Paragraph>
        If you would like to change the status or interview stage of this
        candidate, select the status or interview stage below and then click
        Change button.
      </Typography.Paragraph>
      <Form
        onFinish={handleSuccess}
        layout='vertical'
        form={form}
        className='modal-reason-dropdown mt-5'>
        <Row gutter={[10, 10]}>
          <Col xs={24} md={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Candidate Status'),
                },
              ]}
              name='status'
              label='Change Candidate Status'
              style={{maxWidth: 260, width: '100%'}}>
              <AppSelect
                placeholder={getSelectPlaceHolder('status')}
                size={'middle'}
                style={{maxWidth: 260, width: '100%'}}
                options={CANDIDATE_STATUS_OPTIONS(isIntegrated)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              rules={[
                {
                  required: !isDisableStage,
                  message: getRequiredMessage('Interview Stage'),
                },
              ]}
              name='job_interview_stage'
              label='Change Interview Stage'
              style={{maxWidth: 260, width: '100%'}}>
              <AppSelect
                disabled={isDisableStage}
                placeholder={getSelectPlaceHolder('interview stage')}
                size={'middle'}
                style={{maxWidth: 260, width: '100%'}}
                options={options}
              />
            </Form.Item>
          </Col>
          {isRejected && (
            <>
              <Col xs={24} md={16} lg={16}>
                <Form.Item
                  rules={[
                    {
                      required: isRejected,
                      message: getRequiredMessage('Rejection Reason'),
                    },
                  ]}
                  name='reject_reason'
                  label='Rejection Reason'
                  style={{width: '100%'}}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('reason')}
                    size={'middle'}
                    options={rejectOptions || CANDIDATE_REJECT_REASON_OPTIONS}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={16} lg={16}>
                <Form.Item
                  label='Rejection Notes'
                  name='reject_reason_notes'
                  rules={[
                    {
                      required: isRejected,
                      message: getRequiredMessage('Rejection Notes'),
                    },
                  ]}>
                  <Input.TextArea
                    placeholder='Enter notes'
                    style={{
                      fontSize: 13,
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

ChangeCandidateModal.propTypes = {
  reasonFor: PropTypes.string,
  actionType: PropTypes.string,
  actionLabel: PropTypes.string,
  isOpenModal: PropTypes.bool,
  isContract: PropTypes.bool,
  isIntegrated: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  status: PropTypes.string,
  selectedCandidate: PropTypes.object,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]),
  rejectOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(null),
  ]),
};

export default ChangeCandidateModal;
