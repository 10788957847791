import {CATEGORIES} from 'app/constants';
import {RiBookmark3Fill, RiSearchLine} from 'react-icons/ri';

export const statusesClient = [
  {status: CATEGORIES.OPEN, sort: ['-created_at']},
  {status: CATEGORIES.DRAFT, sort: ['-closed_at']},
  {status: CATEGORIES.CLOSED, sort: ['-closed_at']},
];
export const statusesSupplier = [
  {status: CATEGORIES.POTENTIAL, sort: ['-created_at']},
  {status: CATEGORIES.DRAFT, sort: ['-closed_at']},
  {status: CATEGORIES.CLOSED, sort: ['-closed_at']},
];

export const pagePrimaryTabsClient = (isActive) => {
  const base = [
    {key: 'open', title: 'Open', route: '/requisitions/open'},
    {key: 'draft', title: 'Draft', route: '/requisitions/draft'},
    {key: 'closed', title: 'Closed', route: '/requisitions/closed'},
  ];

  return isActive
    ? [
        ...base,
        {key: 'imported', title: 'Imported', route: '/requisitions/imported'},
      ]
    : base;
};

export const requisitionClientQueries = {
  include: [
    'submissions',
    'engagements',
    'placements',
    'inquiries',
    'total_count',
    'skill_tags',
  ],
};

export const pageQueriesClient = {
  // all: {
  //   queryParams: {},
  //   title: 'All Jobs',
  // },
  open: {
    queryParams: {
      ...requisitionClientQueries,
      status: 'open',
    },
    title: 'Open Jobs',
    label: 'Created',
    rowTitle: 'Open Jobs',
    empty: 'No Open Jobs',
  },
  draft: {
    queryParams: {
      ...requisitionClientQueries,
      status: 'draft',
    },
    title: 'Draft Jobs',
    label: 'Draft',
    rowTitle: 'Draft Jobs',
    empty: 'No Draft Jobs',
  },
  closed: {
    queryParams: {
      ...requisitionClientQueries,
      status: 'closed',
    },
    title: 'Closed Jobs',
    label: 'Closed',
    rowTitle: 'Closed Jobs',
    empty: 'No Closed Jobs',
  },
  imported: {
    queryParams: {
      ...requisitionClientQueries,
      status: 'imported',
    },
    title: 'Imported Jobs',
    label: 'Imported',
    rowTitle: 'Imported Jobs',
    empty: 'No Imported Jobs',
  },
};

export const pagePrimaryTabsSupplier = [
  {key: 'all', title: 'All', route: '/requisitions/all'},
  {key: 'potential', title: 'Potential', route: '/requisitions/potential'},
  {key: 'filled', title: 'Filled', route: '/requisitions/filled'},
  {key: 'closed', title: 'Closed', route: '/requisitions/closed'},
];

export const pageSecondaryTabsSupplier = [
  {
    key: 'bookmarked',
    title: 'Bookmarked',
    icon: <RiBookmark3Fill />,
    route: '/requisitions/bookmarked',
  },
  {
    key: 'browse',
    title: 'Browse Jobs',
    icon: <RiSearchLine />,
    route: '/requisitions/browse',
  },
];

export const requisitionSupplierQueries = {
  include: [
    'total_count',
    'bookmarked',
    'skill_tags',
    'client_relationship_status',
    'client_active_suppliers',
    'client_engaged_resources',
    'client_open_requisitions',
    'common_inquiries',
    'common_submissions',
    'common_engagements',
    'common_placements',
  ],
  expand: ['client'],
};

export const pageQueriesSupplier = {
  all: {
    queryParams: {},
    title: 'All Jobs',
  },
  potential: {
    queryParams: {
      ...requisitionSupplierQueries,
      relation_status: 'potential',
    },
    title: 'Potential Jobs',
    label: 'Updated',
    rowTitle: 'Potential Jobs',
    empty: 'No Potential Jobs',
  },
  filled: {
    queryParams: {
      ...requisitionSupplierQueries,
      relation_status: 'filled',
    },
    title: 'Filled Jobs',
    label: 'Filled',
    rowTitle: 'Filled Jobs',
    empty: 'No Filled Jobs',
  },
  closed: {
    queryParams: {
      ...requisitionSupplierQueries,
      relation_status: 'closed',
    },
    title: 'Closed Jobs',
    label: 'Closed',
    rowTitle: 'Closed Jobs',
    empty: 'No Closed Jobs',
  },
  bookmarked: {
    queryParams: {
      ...requisitionSupplierQueries,
      bookmarked: true,
    },
    title: 'Bookmarked Jobs',
    label: 'Bookmarked',
    rowTitle: 'Bookmarked Jobs',
    empty: 'No Bookmarked Jobs',
  },
  browse: {
    queryParams: {
      ...requisitionSupplierQueries,
    },
    title: 'Browse Jobs',
    label: 'Created',
    rowTitle: 'Jobs',
    empty: 'No Jobs',
  },
};

export const sortParams = {
  potential: [
    {label: `Date updated (new to old)`, value: '-created_at'},
    {label: `Date updated (old to new)`, value: '+created_at'},
  ],
  open: [
    {label: `Date updated (new to old)`, value: '-created_at'},
    {label: `Date updated (old to new)`, value: '+created_at'},
  ],
  draft: [
    {label: `Date updated (new to old)`, value: '-closed_at'},
    {label: `Date updated (old to new)`, value: '+closed_at'},
  ],
  closed: [
    {label: 'Date closed (new to old)', value: '-closed_at'},
    {label: 'Date closed (old to new)', value: '+closed_at'},
  ],
  imported: [
    {label: 'Date imported (new to old)', value: '-created_at'},
    {label: 'Date imported (old to new)', value: '+created_at'},
  ],
  bookmarked: [
    {label: 'Bookmarked date (new to old)', value: '-created_at'},
    {label: 'Bookmarked date (old to new)', value: '+created_at'},
  ],
  browse: [
    {label: 'Date created (new to old)', value: '-created_at'},
    {label: 'Date created (old to new)', value: '+created_at'},
  ],
};
