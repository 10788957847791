import AppCard from '@wieldy/components/AppCard';
import {Row} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import CommonTable from 'app/pages/main/financials/ViewInvoice/MainContent/common';
import PropTypes from 'prop-types';
import React from 'react';

import AddressWidget from './AddressWidget';
import Expenses from './Expenses';
import FeeTable from './FeeTable';
import InvoiceDetail from './InvoiceDetail';
import ServiceFeeTable from './ServiceFees';
import TimeCards from './TimeCards';
import TotalSummary from './TotalSummary';

const MainContent = ({invoice}) => {
  const {isSupplier} = useAccountType();
  const isExternalSup = 'SUP-00001' === invoice?.supplier?.hid;
  return (
    <AppCard headStyle={{borderBottom: 'none'}} bodyStyle={{paddingTop: 8}}>
      <Row justify='space-between' className='mb-8'>
        <AddressWidget
          title='Bill From'
          address={
            isSupplier
              ? invoice.supplier_billing_address
              : invoice.marketplace_billing_address
          }
        />
        <AddressWidget
          title='Bill To'
          address={
            isSupplier
              ? invoice.marketplace_billing_address
              : invoice.client_billing_address
          }
        />
      </Row>

      <div className='mb-12' style={{maxWidth: 650}}>
        <InvoiceDetail invoice={invoice} />
      </div>
      <div className='mb-12'>
        <CommonTable invoice={invoice} />
      </div>

      <div className='mb-12'>
        <FeeTable invoice={invoice} />
      </div>
      <div className='mb-12'>
        <TimeCards invoice={invoice} />
      </div>

      <div className='mb-2'>
        <Expenses invoice={invoice} />
        {isExternalSup && !isSupplier && <ServiceFeeTable />}
        <TotalSummary invoice={invoice} />
      </div>
    </AppCard>
  );
};

MainContent.propTypes = {
  invoice: PropTypes.object,
};

export default MainContent;
