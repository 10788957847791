import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import {
  getInputPlaceHolder,
  getRequiredMessage,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {SkillTagDropdown} from 'app/shared/components/dropdowns';
import Editor from 'app/shared/components/Editor/Editor';
import {isJson} from 'app/utils/helpers/isJson';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

const InformationForm = ({initialValues, onSave, onContinue}) => {
  const [skills, setSkills] = React.useState(initialValues?.skillTags);
  const [form] = Form.useForm();

  const handleChangeSkills = React.useCallback((_, options) => {
    setSkills(options);
  }, []);

  const onFormSubmit = (formData) => {
    formData.skill_tags = skills;
    onSave(formData);
    onContinue();
  };
  const getValue = useCallback(
    (field) => {
      return (blocks) => {
        if (blocks?.length) {
          form.setFieldValue(field, blocks);
        } else {
          form.setFieldValue(field, undefined);
        }
      };
    },
    [form],
  );
  const getEditorInitValue = useCallback((value) => {
    return value
      ? isJson(value)
        ? JSON.parse(value)
        : [{type: 'paragraph', data: {text: value}}]
      : [];
  }, []);

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item name='job_overview' label='Overview'>
              <Editor
                getEditorValue={getValue('job_overview')}
                key={initialValues.job_overview || 'overview'}
                placeholder={getInputPlaceHolder('overview')}
                initValue={getEditorInitValue(initialValues.job_overview)}
              />
            </Form.Item>

            <Form.Item name='job_responsibilities' label='Responsibilities'>
              <Editor
                getEditorValue={getValue('job_responsibilities')}
                key={
                  initialValues.job_responsibilities || 'job_responsibilities'
                }
                placeholder={getInputPlaceHolder('responsibilities')}
                initValue={getEditorInitValue(
                  initialValues.job_responsibilities,
                )}
              />
            </Form.Item>

            <Form.Item name='job_qualifications' label='Qualifications'>
              <Editor
                getEditorValue={getValue('job_qualifications')}
                key={initialValues.job_qualifications || 'job_qualifications'}
                placeholder={getInputPlaceHolder('skills/experience')}
                initValue={getEditorInitValue(initialValues.job_qualifications)}
              />
            </Form.Item>

            <Form.Item
              name='skill_tags'
              label='Skill Tags'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Skill Tags'),
                },
              ]}>
              <SkillTagDropdown
                options={skills}
                onChange={handleChangeSkills}
                placeholder='Search for skills'
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
