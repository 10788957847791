import AppTable from '@wieldy/components/AppTable';
import {Typography} from 'antd';
import moment from 'moment';
import React from 'react';

import {DATE_FORMATS} from '../../../../../../config/app';
import {useGetServiceColumns} from './columns';

const ServiceFeeTable = () => {
  const columns = useGetServiceColumns();

  const data = [
    {
      end_date: moment().endOf('month').format(DATE_FORMATS['/']),
      description: 'Monthly Service Fee',
      qty: 1,
      total: 50,
      tax: 0,
      final: 50,
    },
  ];

  return (
    <>
      <Typography.Title level={4} className={'mb-4'}>
        Service Fees
      </Typography.Title>
      <AppTable columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

export default ServiceFeeTable;
