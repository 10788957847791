import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import PropTypes from 'prop-types';

const MetricCard = ({count, title, icon}) => {
  return (
    <AppCard
      title={[count || 0].toString()}
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}
      className='dashboard-card'
      extra={icon}>
      {title && (
        <Typography.Title level={5} className={'text-secondary text-sm mb-0'}>
          <span dangerouslySetInnerHTML={{__html: title}} />
        </Typography.Title>
      )}
    </AppCard>
  );
};

MetricCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
};

export default MetricCard;
