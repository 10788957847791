import {Divider, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const RequisitionCardHeader = ({requisition, header}) => {
  const navigate = useNavigate();
  return (
    <>
      {header}
      <Row justify='space-between' wrap={false}>
        <div className={'d-flex flex-column flex-fill'}>
          <Typography.Title ellipsis level={4} className={'mb-1'}>
            <Typography.Link
              onClick={() =>
                navigate(`/requisitions/${requisition?.id}/view-requisition`)
              }>
              {requisition?.jobTitle}
            </Typography.Link>
          </Typography.Title>
          <Typography.Paragraph className={'mb-2 text-sm'}>
            <Typography.Text type={'secondary'}>
              {`Opened on ${requisition?.created}`}
            </Typography.Text>
            <Divider type='vertical' />
            <Typography.Text type={'secondary'}>
              {`Updated ${requisition?.updated} ago`}
            </Typography.Text>
          </Typography.Paragraph>
        </div>
      </Row>
      <Divider style={{margin: '16px -24px', width: 'auto'}} />
    </>
  );
};

RequisitionCardHeader.propTypes = {
  requisition: PropTypes.object,
  header: PropTypes.node,
};

export default RequisitionCardHeader;
